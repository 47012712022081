import React from "react";
import styled from "styled-components";
import Layout from "~components/layouts/falcons";
import IntroSection from "~components/ui/intro-section";
import ContentSection from "~components/ui/content-section";
import { useFetchAllFalconsContent } from "~hooks/use-fetch-all-falcons-content";
import IntroBg from "~assets/images/falcons-intro-bg.webp";
import { slugify } from "~src/utils/strings";

const IndexPage = () => {
  const sections = useFetchAllFalconsContent();
  const team = "falcons";

  return (
    <Layout>
      <MainScrollContainer>
        <IntroSection
          title='Dirty Birds'
          copy='Welcome to Atlanta'
          bgSrc={IntroBg}
          bgX='center'
          bgY='center'
          nextSectionUrl={`/${team}#red-helmet`}
        />

        {sections &&
          sections.length > 0 &&
          sections.map((section, i) => {
            // unity kit section is the same year as another section
            const name = section.frontmatter.title;
            const isLast = i === sections.length - 1;
            const isFirst = i === 0;
            const isEven = i % 2 === 0;
            const id = name ? slugify(name) : section.slug;

            // allow content to override right alignment
            const rightAlign = section.frontmatter?.align
              ? section.frontmatter.align === "right"
              : !isEven;

            return (
              <ContentSection
                key={`section-${section.slug}`}
                slug={section.slug}
                id={id}
                body={section.body}
                year={section.frontmatter.title}
                title={section.frontmatter.copy}
                copy={section.frontmatter.cta}
                mediaType={section.frontmatter.mediaType}
                src={section.frontmatter.src}
                poster={section.frontmatter.poster}
                trackingLabel={section.frontmatter.trackingLabel}
                samples={section.frontmatter.partners}
                ctaText={section.frontmatter.ctaText}
                ctaIcon={section.frontmatter.ctaIcon}
                ctaLink={section.frontmatter.ctaLink}
                autoplay={section.frontmatter.autoplay}
                fieldOfView={section.frontmatter.fieldOfView}
                index={i}
                isLast={isLast}
                isFirst={isFirst}
                right={rightAlign}
              />
            );
          })}
      </MainScrollContainer>
    </Layout>
  );
};

const MainScrollContainer = styled.main``;

export default IndexPage;
