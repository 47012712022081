import { useStaticQuery, graphql } from "gatsby";

export const useFetchAllFalconsContent = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query FetchAllFalconsContent {
        allMdx(
          sort: { fields: frontmatter___order, order: ASC }
          filter: { fileAbsolutePath: { regex: "/.*pages/falcons/.*/" } }
        ) {
          nodes {
            slug
            body
            frontmatter {
              order
              title
              copy
              cta
              align
              mediaType
              src
              poster
              trackingLabel
              ctaText
              ctaIcon
              ctaLink
              autoplay
              fieldOfView
              partners {
                title
                copy
                cause
                location
                link
                logo {
                  childImageSharp {
                    gatsbyImageData(
                      width: 196
                      layout: FIXED
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            fileAbsolutePath
          }
        }
      }
    `,
  );

  return allMdx?.nodes ?? [];
};
